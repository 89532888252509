import React from 'react';
//import { useLocation } from 'react-router-dom';
import Header from '../../components/Common/Header/Header';
import Footer from '../../components/Common/Footer/Footer';

const Layout = ({ children }) => {
  //const location = useLocation();

  return (
    <div className="main-parts">
      <Header />
      <main>
        {children}
      </main>
     <Footer />
    </div>
  );
};

export default Layout;