import React, {  useEffect } from 'react'; 
import { Container, Row, Col } from 'react-bootstrap'; 

import AOS from "aos";
import "aos/dist/aos.css";

const GlobalTrade = () => {
    useEffect(() => {
        AOS.init();
        AOS.refresh();
      }, []);
    return (
       <section className='global_trade pb-4 mb-5'>
            <Container>
                <Row>
                    <Col lg="6" md="12">
                        <div className='global_text_left ' data-aos="fade-up"
     data-aos-duration="3000">
                            <h2>Bridging Global Trade, Connecting Opportunities Worldwide</h2>
                            <p className='custom-font1'>Welcome to Adzguru Exports LLP, your premier partner in global trade connections. As a dynamic and forward-thinking company, we specialize in fostering international collaborations that transcend borders and unlock unparalleled business opportunities. Our commitment to excellence, integrity, and innovation positions us as a leading   force in the world of trade facilitation.</p>
                            
                        </div>
                    </Col>
                    <Col lg="6" md="12">
                        <div className='global_text_left' data-aos="fade-up"
     data-aos-duration="3000">
                            <p className='custom-font1 '>At Adzguru Exports LLP, we understand the intricate dynamics of global commerce, and our dedicated team works tirelessly to streamline trade processes and foster mutually beneficial relationships. Whether you're a seasoned exporter or just venturing into the global market, our expertise ensures a seamless experience. Our comprehensive approach, coupled with cutting-edge technology, sets us apart. Join us in shaping the future of international trade – where Adzguru Exports becomes your trusted catalyst for success. Explore limitless possibilities, connect with key players, and thrive in the ever-
evolving landscape of global business.</p>
                        </div>
                    </Col>
                </Row>
            </Container>
       </section>

        ); 
}; 
export default GlobalTrade;