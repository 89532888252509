import React, { useState, useEffect } from 'react'; 
import { Container, Row, Col } from 'react-bootstrap'; 
import { animateScroll as scroll } from 'react-scroll';
import AOS from "aos";
import "aos/dist/aos.css";
const WhoWeAre = () => {
    useEffect(() => {
        AOS.init();
        AOS.refresh();
      }, []);
    return (
        <section className='who_we_are py-4 my-5' id='about_us' data-aos="fade-up"
        data-aos-duration="3000">
            <Container>
                <Row>
                    <Col lg="6" md="12">
                        <div className='who_left_partts'>
                            <h2 className='pb-2'>Who We Are</h2>
                            <h3 className='mb-4'>About Us</h3>
                            <p className='custom-font1'>Established with a vision to bridge the gap between Indian and Australian businesses and the flourishing markets worldwide, Adzguru Exports LLP has emerged as a reliable name in the realm of global trade. Our commitment to excellence, combined with a deep understanding of the intricacies of international commerce, sets us apart as a leader in the industry.</p>
                        </div>
                    </Col>
                    <Col lg="6" md="12">
                        <div className='who_left_partts'>
                            <p className='custom-font1'>At Adzguru Exports LLP, we recognize the challenges businesses face when expanding into new territories. That's why we are dedicated to providing comprehensive solutions that go beyond traditional import-export services. We don't just move goods; we open doors to new opportunities. Our team of experienced professionals ensures a smooth and efficient process, guiding clients through every step of the journey.</p>
                            <p className='custom-font1'>With a focus on integrity, transparency, and customer satisfaction, Adzguru Exports LLP has become a trusted advisor for businesses looking to explore and conquer markets in Australia and Papua New Guinea. Whether you are a seasoned player or a newcomer to international trade, we are here to empower your business and elevate it to new heights.</p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
        ); 
    }; 
    export default WhoWeAre;