import React, {useEffect } from 'react'; 
import { Container,  } from 'react-bootstrap'; 
import { NavLink } from 'react-router-dom';
import BannerRightImg from "../../images/banner_right_img.jpg";
import BannerBottomImg from "../../images/banner_bottom_image.jpg";

import AOS from "aos";
import "aos/dist/aos.css";
const BannerPartts = () => {

    useEffect(() => {
        AOS.init();
        AOS.refresh();
      }, []);
     return (
        <section className='banner'>
            <div className='banner_inner'>
                <div className='inner_banner_inner'>
                    <Container>
                        <div className='banner_container'>
                            <div className='banner_container_left' >
                                <div data-aos="fade-up">
                                <h2 className='pt-5'>Navigate Globally</h2>
                                <h4>Prosper Locally</h4>
                                <p>Unlocking Global Markets for Indian & Australian Businesses One export at a time</p>
                                <div className=' pb-5'>
                                <div className='get_started_button'>
                                    <NavLink to ='#'>Get Started</NavLink>
                            
                                </div>
                                </div>
                                </div>
                               
                            </div>
                            <div className='banner_container_right' data-aos="zoom-in">
                                <img src={BannerRightImg} />
                            </div>
                        </div>
                    </Container>                
                </div>
            </div>
            <div className='banner_bottom_image'>
                <img src={BannerBottomImg} />
            </div>
            <div className='banner_svg'>
                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 2000 120" preserveAspectRatio="none">
	                <polygon fill="#FFF" points="-11,2 693,112 2019,6 2019,135 -11,135 "></polygon>
                </svg>
            </div>
        </section>
        ); 
}; 
export default BannerPartts;