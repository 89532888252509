import React, { useState, useEffect } from 'react'; 
import { Container, Row, Col } from 'react-bootstrap'; 
import { animateScroll as scroll } from 'react-scroll';
import ServicesLeftImg from "../../images/services_left_img.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AOS from "aos";
import "aos/dist/aos.css";
const Services = () => {
    useEffect(() => {
        AOS.init();
        AOS.refresh();
      }, []);
    return (
        <section className='services py-5' id='services' >
            <div className='services_top'></div>
            <Container className='mt-3 mb-5'>
                <Row className='align-items-center'>
                    <Col lg="7" md="6" className='pe-lg-5 mb-4 mb-lg-0'>
                        <div className='serv_left_img'>
                            <img src={ServicesLeftImg} />
                        </div>
                    </Col>
                    <Col lg="5" md="6">
                        <div className='services_right' data-aos="fade-right"
     data-aos-duration="3000">
                            <h2 className='mb-3'>Our Services</h2>
                            <h3>Empowering Trade Beyond Borders</h3>
                            <p className='custom-font1 pt-3 pb-1 mb-3'>Adzguru empowers businesses by facilitating seamless product export world wide , unlocking untapped markets for entrepreneurs. With expertise in international trade, Adzguru streamlines the process, ensuring a smooth transition into new territories. By navigating complexities and providing strategic insights, Adzguru becomes a trusted ally for business owners looking to expand their global footprint. From market research to logistics coordination, Adzguru's comprehensive approach fosters growth, enabling businesses to thrive in diverse and lucrative markets. With a commitment to excellence, Adzguru is the catalyst for success, connecting businesses to unparalleled opportunities in the ever-expanding Pacific marketplace.</p>

                            <ul>
                                <li className='mb-3'><span className='li_font'><FontAwesomeIcon icon="fa-solid fa-check" /></span>Global Trade Facilitation</li>
                                <li className='mb-3'><span className='li_font'><FontAwesomeIcon icon="fa-solid fa-check" /></span>Expertise in International Trade</li>
                                <li className='mb-3'><span className='li_font'><FontAwesomeIcon icon="fa-solid fa-check" /></span>Strategic Insights and Guidance</li>
                                <li className='mb-3'><span className='li_font'><FontAwesomeIcon icon="fa-solid fa-check" /></span>Comprehensive Growth Solutions</li>
                            </ul>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
        ); 
    }; 
export default Services;