import React from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import FooerLogo from "../../../images/adzguru final logo-India.png";
import { Link as ScrollLink} from 'react-scroll';
function MyNavbar() {
  return (
    <Navbar expand="lg">
        <a href="https://www.adzguruexports.com/" title="Adzguru Exports - " className="overlay-logo me-auto overlay-logo-transition header_logo">
        <img src={FooerLogo} style={{ width: '290px' }} alt="Adzguru Exports" />
        </a>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            {/* <Nav.Link href="#products">Products</Nav.Link>
            <Nav.Link href="#about_us">About Us</Nav.Link>
            <Nav.Link href="#services">Services</Nav.Link>
            <Nav.Link href="#how_we_work">How We Work</Nav.Link>
            <Nav.Link href="#contact-us">FAQ</Nav.Link>
            <Nav.Link href="#contact-us">Contact</Nav.Link> */}


            <ScrollLink to="products" spy={true} smooth={true} offset={-70} duration={500} className="nav-link">Products</ScrollLink>
            <ScrollLink to="about_us" spy={true} smooth={true} offset={-70} duration={500} className="nav-link">About Us</ScrollLink>
            <ScrollLink to="services" spy={true} smooth={true} offset={-70} duration={500} className="nav-link">Services</ScrollLink>
            <ScrollLink to="how_we_work" spy={true} smooth={true} offset={-70} duration={500} className="nav-link">How We Work</ScrollLink>
            <ScrollLink to="contact-us" spy={true} smooth={true} offset={-70} duration={500} className="nav-link">FAQ</ScrollLink>
            <ScrollLink to="contact-us" spy={true} smooth={true} offset={-70} duration={500} className="nav-link">Contact</ScrollLink>
          </Nav>
        </Navbar.Collapse>
    </Navbar>
  );
}

export default MyNavbar;
