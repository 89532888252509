import React, { useState, useEffect } from 'react'; 
import { Container, Row, Col } from 'react-bootstrap'; 
import { library } from '@fortawesome/fontawesome-svg-core';
import FooerLogo from "../../../images/footer-logo.png";
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NavLink } from 'react-router-dom';
import NavBar from "../Header/NavBar";

library.add(fas, far, fab);

const Header = () => {
  const [visible, setVisible] = useState(true);
  const [width, setWidth] = useState(window.innerHeight);
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [visible1, setVisible1] = useState(false);
  const [isAtTop, setIsAtTop] = useState(true);

  const handleVisibility = () => {
    setVisible(prev => !prev);
  };

  const handleWidth = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    console.log(visible);
    window.addEventListener("resize", handleWidth);
    width <= 600 ? setVisible(false) : setVisible(false);
    return () => {
      window.removeEventListener("resize", handleWidth);
    };
  }, [width]);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      setVisible1(prevScrollPos > currentScrollPos || currentScrollPos < 0);
      setIsAtTop(currentScrollPos === 0);
      setPrevScrollPos(currentScrollPos);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [prevScrollPos]);

  return (
    <div className="header-wrapper">
      <header id="header" className="header-builder header-builder-p logo-overlay-header header-loaded">
        <div className='header_top'>
          <Container className="porto-wrap-container">
            <Row>
              <Col md="12">
                <div className="column-inner share_icons">
                  <div className="mb-0 me-auto text-color-dark">
                    <div className="wpb_wrapper"></div>
                  </div>
                  <div className="share-links">
                    <NavLink className="share-facebook" to="https://www.facebook.com/profile.php?id=61555425395036" title="Facebook">
                      <FontAwesomeIcon icon={['fab', 'facebook-f']} />
                    </NavLink>
                    <NavLink className="share-instagram" to="https://www.instagram.com/adzguruexports/" title="Instagram">
                      <FontAwesomeIcon icon={['fab', 'instagram']} />
                    </NavLink>
                    <NavLink className="share-linkedin" to="https://www.linkedin.com/company/adzguru-export-llp/" title="LinkedIn">
                      <FontAwesomeIcon icon={['fab', 'linkedin-in']} />
                    </NavLink>
                  </div>
                  <div className="mb-0 btn-triangle d-none d-md-block btn3-inline">
                    <NavLink className="btn btn-modern btn-md btn-primary quote_button" to="#contact-us" title="Contact Us">
                      Get A Quote
                      <FontAwesomeIcon icon={['fas', 'long-arrow-alt-right']} />
                    </NavLink>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className={`header_bottom ${visible1 && !isAtTop ? 'active' : 'hidden'}`}>
          <div className="porto-wrap-container container">
            <Row>
              <Col md="12" className="pe-lg-0">
                <div className="inner header_partts">
                  <NavBar />
                  <span className="separator d-none d-lg-block"></span>
                  <div className="searchform-popup search-popup">
                    <button className="search-toggle" aria-label="Search Toggle" onClick={handleVisibility}>
                      <FontAwesomeIcon icon="fa-solid fa-magnifying-glass" /><span className="search-text">Search</span>
                    </button>
                    <form method="get" className={visible ? "searchform search-layout-simple open" : "searchform search-layout-simple close"}>
                      <div className="searchform-fields">
                        <span className="text">
                          <input name="s" type="text" placeholder="Search…" autoComplete="off" className="porto-search-init" />
                        </span>
                        <span className="button-wrap">
                          <button className="btn btn-special" title="Search" type="submit">
                            <FontAwesomeIcon icon="fa-solid fa-magnifying-glass" />
                          </button>
                        </span>
                      </div>
                      <div className="live-search-list">
                        <div className="autocomplete-suggestions"></div>
                      </div>
                    </form>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
        <div id="nav-panel">
          <Container>
            <div className="mobile-nav-wrap">
              <div className="menu-wrap">
                <ul className="mobile-menu accordion-menu">
                  <li className="menu-item menu-item-type-custom menu-item-object-custom active"><NavLink to="#products">Products</NavLink></li>
                  <li className="menu-item menu-item-type-custom menu-item-object-custom"><NavLink to="#about_us">About Us</NavLink></li>
                  <li className="menu-item menu-item-type-custom menu-item-object-custom"><NavLink to="#services">Services</NavLink></li>
                  <li className="menu-item menu-item-type-custom menu-item-object-custom"><NavLink to="#how_we_work">How We Work</NavLink></li>
                  <li className="menu-item menu-item-type-custom menu-item-object-custom"><NavLink to="#contact-us">FAQ</NavLink></li>
                  <li className="menu-item menu-item-type-custom menu-item-object-custom"><NavLink to="#contact-us">Contact</NavLink></li>
                </ul>
              </div>
            </div>
          </Container>
        </div>
      </header>
    </div>
  );
};

export default Header;
