import React, { useState, useEffect } from 'react'; 
import { Container, Row, Col } from 'react-bootstrap'; 
import { animateScroll as scroll } from 'react-scroll';
import ServicesLeftImg from "../../images/services_left_img.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AOS from "aos";
import "aos/dist/aos.css";
const HowWeWork = () => {
    useEffect(() => {
        AOS.init();
        AOS.refresh();
      }, []);
    return (
        <section className='how_we_work py-5 mt-4' id="how_we_work">
            <Container>
                <Row>
                    <Col lg="8" md="12" className='mx-auto pt-2 mb-5'>
                        <div className='we_work_text' data-aos="fade-left">
                            <h2 className='mb-3'>How we work</h2>
                            <p>Pioneering Success through Transparency, 
                                Innovation, and Global Efficiency.</p>
                        </div>
                    </Col>
                    <Col md="12">
                        <div className='porto-process process-horizontal mb-0'>
                            <div className='process-step' data-aos="zoom-in"  data-aos-offset="100">
                                <div className='process-step-circle'>
                                    <strong className="process-step-circle-content">1</strong>
                                </div>
                                <div className='process-step-content'>
                                    <h4 className='step-item-title'>Transparent Collaboration</h4>
                                    <div className='process-step-desc'>We foster open communication, ensuring transparency at every stage, building trust and lasting partnerships.</div>
                                </div>
                            </div>
                            <div className='process-step' data-aos="zoom-in"  data-aos-offset="300">
                                <div className='process-step-circle'>
                                    <strong className="process-step-circle-content">2</strong>
                                </div>
                                <div className='process-step-content'>
                                    <h4 className='step-item-title'>Strategic Innovation</h4>
                                    <div className='process-step-desc'>Harnessing cutting-edge strategies, we innovate with purpose, adapting to market dynamics for sustainable business growth.</div>
                                </div>
                            </div>
                            <div className='process-step' data-aos="zoom-in"  data-aos-offset="400">
                                <div className='process-step-circle'>
                                    <strong className="process-step-circle-content">3</strong>
                                </div>
                                <div className='process-step-content'>
                                    <h4 className='step-item-title'>Efficient Global Expansion</h4>
                                    <div className='process-step-desc'>From meticulous market research to streamlined logistics, we execute a seamless global expansion strategy for your success.</div>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
        ); 
    }; 
export default HowWeWork;