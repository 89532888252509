import React, { useState, useEffect } from 'react'; 
import { Container, Row, Col } from 'react-bootstrap'; 
import { animateScroll as scroll } from 'react-scroll';
import NgossieImg from "../../images/ngossie-v2.jpg";
import DobermannImg from "../../images/dobermann-v2.jpg";
import AOS from "aos";
import "aos/dist/aos.css";
const Products = () => {
    useEffect(() => {
        AOS.init();
        AOS.refresh();
      }, []);
    return (

        <section className='products ' id='products'>
            <div className='tri-top'>
                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 2000 120" preserveAspectRatio="none">
	                <polygon fill="#FFF" points="-11,2 693,112 2019,6 2019,135 -11,135 "></polygon>
                </svg>
            </div>
            <div className='inner_prd '>
                <Container className='pt-3 mt-5'>
                    <div className='brands_heading' data-aos="fade-up"
     data-aos-duration="3000">
                        <h2 className='mb-3'>Our Brands</h2>
                        <p className='col-xl-8 col-lg-9 px-0 custom-font1 text-center mx-auto mb-5'>Explore Opulence with Ngossie Leather Wallets, Step into Safety and Style with Dobermann's Protective Footwear</p>
                    </div>

                    <div className='prd_inner'>
                        <div className='prd_inner_left' data-aos="fade-up"
     data-aos-duration="3000">
                            <div className='img_border'>
                                <a href='/'><img src={NgossieImg} /></a>
                            </div>
                            <h2><a href='/'>NGOSSIE</a></h2>
                            <p>Indulge in luxury with Ngossie, crafting premium leather wallets that redefine elegance and sophistication.</p>
                            <div className='mt-3'>
                                <div className='get_started_button text-center'>
                                    <a href='#'>Know More</a>
                                </div>
                            </div>
                        </div>
                        <div className='prd_inner_right' data-aos="fade-up"
     data-aos-duration="3000">
                            <div className='img_border'>
                                <a href='#'><img src={DobermannImg} /></a>
                            </div>
                            <h2><a href='/'>DOBERMANN</a></h2>
                            <p>Step into safety and style with Dobermann – a brand committed to protective footwear and wares, ensuring your well-being at every step.</p>
                            <div className='mt-3'>
                                <div className='get_started_button text-center'>
                                    <a href='#'>Know More</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>
                
            </div>      
        </section>

        ); 
}; 
export default Products;