import React, { useState, useEffect } from 'react'; 
import { Container, Row, Col } from 'react-bootstrap'; 
import { animateScroll as scroll } from 'react-scroll';
import BannerPartts from '../../components/Home/Banner';
import GlobalTrade from "../../components/Home/GlobalTrade";
import Products from "../../components/Home/Products";
import WhoWeAre from "../../components/Home/WhoWeAre";
import Services from "../../components/Home/Services";
import BusinessGlobally from "../../components/Home/BusinessGlobally";
import HowWeWork from "../../components/Home/HowWeWork";
import Faq from "../../components/Home/Faq";
const HomePartts = () => {
     return (
        <section className='home_partts'>
            <BannerPartts />
            <GlobalTrade />
            <Products />
            <WhoWeAre />
            <Services />
            <BusinessGlobally />
            <HowWeWork />
            <Faq />
        </section>
    ); 
}; 
export default HomePartts;
    