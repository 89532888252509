import React, { useState, useEffect } from 'react'; 
import { Container, Row, Col } from 'react-bootstrap';
import FooerLogo from "../../../images/footer-logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Footer = () => {
    // State to manage visibility of the back to top button
    const [showButton, setShowButton] = useState(false);

    // Function to handle scrolling
    const handleScroll = () => {
        // If the user scrolls down more than 400 pixels, show the button, else hide it
        if (window.scrollY > 400) {
            setShowButton(true);
        } else {
            setShowButton(false);
        }
    };

    useEffect(() => {
        // Add event listener for scroll
        window.addEventListener('scroll', handleScroll);
        // Clean up function to remove event listener
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    // Function to scroll to the top of the page
    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    return (
        <footer id="footer" className='footer footer-builder'>
            <div className='tri-top shape-reverse footer_top'>
                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 2000 120" preserveAspectRatio="none">
                    <polygon fill="#FFF" points="-11,2 693,112 2019,6 2019,135 -11,135 "></polygon>
                </svg>
            </div>
            <div className='porto-inner-container'>
                <Container>
                    <Row>
                        <Col lg="3" md="12">
                            <div className='footer_left'>
                                <div className='FooerLogo mb-4 mt-5'>
                                    <img src={FooerLogo}  width="202px" height="54px" alt="Footer Logo"/>
                                </div>
                                <h3>Adzguru Exports LLP</h3>
                                <p>Connecting Business Worldwide</p>
                                <div className='share-links'>
                                    <a href='https://www.facebook.com/profile.php?id=61555425395036' target='_blank' rel="noopener noreferrer">
                                        <FontAwesomeIcon icon={['fab', 'facebook-f']} />
                                    </a>
                                    <a href="https://www.instagram.com/adzguruexports/" target='_blank' rel="noopener noreferrer">
                                        <FontAwesomeIcon icon={['fab', 'instagram']} />
                                    </a>
                                    <a href="https://www.linkedin.com/company/adzguru-export-llp/" target='_blank' rel="noopener noreferrer">
                                        <FontAwesomeIcon icon={['fab', 'linkedin']} />
                                    </a>
                                </div>
                            </div>
                        </Col>
                        <Col lg="6" md="12">
                            <div className='footer_middle w-lg-66 w-md-100 ms-lg-auto me-lg-auto mt-4 mt-lg-0'>
                                <h3 className='mb-3'>USEFUL LINKS</h3>
                                <div className='footer_list'>
                                    <ul className="list list-unstyled mb-0">
                                        <li><a href="#products">Products</a></li>
                                        <li><a href="#about_us">About Us</a></li>
                                        <li><a href="#services">Services</a></li>
                                        <li><a href="#how_we_work">How We Work</a></li>
                                        <li><a href="#contact">FAQs</a></li>
                                        <li><a href="#contact">Contact Us</a></li>
                                    </ul>
                                    <ul className="list list-unstyled mb-0">
                                        <li className="mb-0"><a href="#">Privacy Policy</a></li>
                                        <li><a href="#">Terms And Conditions</a></li>
                                    </ul>
                                </div>
                            </div>
                        </Col>
                        <Col lg="3" md="12">
                            <div className='footer_right'>
                                <h3>Connect With Us</h3>
                                <ul>
                                    <li><strong>Registered Office:</strong></li>
                                </ul>
                                <p className='p-t-sm p-b-sm'>P-7, Purbalaya, Madhyamgram, Kolkata, West Bengal 700129</p>
                                <ul>
                                    <li><strong>Corporate Office:</strong></li>
                                </ul>
                                <p className='p-t-sm p-b-sm'>8/1/2 Loudon Street, 3rd Floor, Surabhi Building, Kolkata, West Bengal 700017</p>
                                <ul>
                                    <li><a href="#">Mobile - +91 8910316086</a></li>
                                </ul>
                                <hr className='separator-line  align_center solid' />
                                <ul>
                                    <li>
                                        <a href="mailto:reachus@adzguruexports.com">Email: reachus@adzguruexports.com</a>
                                    </li>
                                </ul>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className='footer_bottom'>
                <Container>
                    <div className='footer_bottom_inner'>
                        <p className="mb-0">Adzguru © 2024. All Rights Reserved.</p>
                    </div>
                </Container>
            </div>
            {/* Back to top button */}
            <button className={`back-to-top ${showButton ? 'visible' : ''}`} onClick={scrollToTop}>
                <FontAwesomeIcon icon="arrow-up" />
            </button>
        </footer>
    );
}; 
export default Footer;
