import React, { useState, useEffect } from 'react'; 
import { Container, Row, Col, Button, Form, Accordion, Alert } from 'react-bootstrap'; 
import AOS from "aos";
import "aos/dist/aos.css";

const Faq = () => {
    useEffect(() => {
        AOS.init();
        AOS.refresh();
    }, []);

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [showNotification, setShowNotification] = useState(false);

    const handleSendEmail = (e) => {
        e.preventDefault();

        // Logic for handling form submission

        // Display notification
        setShowNotification(true);

        // Clear form fields
        setName('');
        setEmail('');
        setMessage('');

        // Hide notification after 3 seconds
        setTimeout(() => {
            setShowNotification(false);
        }, 3000);
    };

    return (
        <section className='contact-us' id="contact-us">
            <div className='faq_inner_bottom py-5 my-5'>
                <Container>
                    <Row>
                        <Col lg="8" md="12" className='mx-auto pt-2 mb-5 margin_bottom_50'>
                            <div className='we_work_text faq_text' data-aos="fade-left">
                                <h3 className="mb-3">Frequently Asked Questions</h3>
                                <h2 className='mb-3'>Your Top Questions Answered</h2>
                                <p className='custom-font1'>Navigating Global Business Success with Adzguru's Expertise</p>
                            </div>
                        </Col>
                        <Col md="12" lg="6" className='mb-5 mb-lg-0'>
                            <Accordion flush className="accrodion-grp faq-one-accrodion" data-grp-name="faq-one-accrodion">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header><h4>How does Adzguru simplify the global export process for businesses?</h4></Accordion.Header>
                                    <Accordion.Body>
                                        <div className="inner">
                                            <p>Adzguru streamlines exports by providing expert guidance, from documentation to logistics, ensuring a seamless transition into new markets.</p>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="1">
                                    <Accordion.Header><h4>What sets Adzguru apart in international trade facilitation?</h4></Accordion.Header>
                                    <Accordion.Body>
                                        <div className="inner">
                                            <p>Adzguru stands out with its expertise, transparent collaboration, and strategic innovation, offering a comprehensive approach to global business expansion.</p>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="3">
                                    <Accordion.Header><h4>Can Adzguru assist in market research for new territories?</h4></Accordion.Header>
                                    <Accordion.Body>
                                        <div className="inner">
                                            <p>Absolutely! Adzguru specializes in market research, providing valuable insights to help businesses make informed decisions in their global ventures.</p>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="4">
                                    <Accordion.Header><h4>How does Adzguru navigate the complexities of diverse markets?</h4></Accordion.Header>
                                    <Accordion.Body>
                                        <div className="inner">
                                            <p>Adzguru acts as a trusted ally, offering strategic insights and tailored solutions, ensuring businesses navigate market complexities successfully.</p>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="5">
                                    <Accordion.Header><h4>What types of businesses benefit most from Adzguru's services?</h4></Accordion.Header>
                                    <Accordion.Body>
                                        <div className="inner">
                                            <p>Adzguru caters to a wide range of businesses, from startups to established enterprises, seeking to expand globally and thrive in diverse markets.</p>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </Col>
                        <Col md="12" lg="6">
                            <div className='question_form'>
                                <Form onSubmit={handleSendEmail}>
                                    <Form.Group className="mb-3" controlId="formBasicName">
                                        <Form.Control type="text" placeholder="Your Name" value={name} onChange={(e) => setName(e.target.value)} className='form_text_field' />
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <Form.Control type="email" placeholder="Email Address" value={email} onChange={(e) => setEmail(e.target.value)} className='form_text_field' />
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="formBasicMsg">
                                        <Form.Control as="textarea" rows={6} placeholder='Your Message' value={message} onChange={(e) => setMessage(e.target.value)} className='form_text_area' />
                                    </Form.Group>
                                    <div className='submit_but'>
                                        <Button variant="primary" className='submit_button_form1' type="button">
                                            Send Message
                                        </Button>
                                    </div>
                                </Form>
                                {showNotification && (
                                    <Alert variant="success" className='mt-3'>
                                        Your message has been sent successfully!
                                    </Alert>
                                )}
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </section>
    ); 
}; 

export default Faq;
