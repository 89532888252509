import React, { useState, useEffect } from 'react';

import './App.css';
import "./styles/css/custom.css";
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Layout from './components/Layout/Layout';
import Home from './pages/Home/Home';
import 'bootstrap/dist/css/bootstrap.min.css';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import {  Row, Col, Form } from "react-bootstrap";

function App() {
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });

  useEffect(() => {
    const timer = setTimeout(() => setShowModal(true), 2000);
    return () => clearTimeout(timer); // Clean up the timer
  }, []);

  const handleClose = () => setShowModal(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Logic for handling form submission

    // Reset form data
    setFormData({
      name: '',
      email: '',
      message: ''
    });

    setShowModal(false);
  };

  return (
    <Router>
      <Layout>
        <Switch>
          <Route exact path="/" component={Home} />
        </Switch>
      </Layout>
      <Modal show={showModal} size="lg" centered onHide={handleClose}>
        <Modal.Body className='name_field_body'>
          <div className='cross_butt'>
            <Button variant="primary" onClick={handleClose}>
              X
            </Button>
          </div>
          <div className="name_field">
            <Form onSubmit={handleSubmit}>
              <Row>
                <Col lg="6" md="12">
                  <Form.Group className="mb-5">
                    <Form.Control
                      type="text"
                      placeholder="Your Name"
                      className='name_text'
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
                <Col lg="6" md="12">
                  <Form.Group className="mb-5">
                    <Form.Control
                      type="text"
                      placeholder="Email Address"
                      className='name_text'
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col lg="12" md="12">
                  <Form.Group className="mb-5">
                    <Form.Control
                      as="textarea"
                      rows={5}
                      placeholder='Your Message'
                      className='text_area'
                      name="message"
                      value={formData.message}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <div className="submit_but">
                <Button type="submit" className="submit_button_form1 btn btn-primary">
                  Send Message
                </Button>
              </div>
            </Form>
          </div>
        </Modal.Body>
      </Modal>
    </Router>
  );
}

export default App;
