import React, {useEffect } from 'react'; 
import { Container, Row, Col } from 'react-bootstrap'; 
import { NavLink } from 'react-router-dom';

import AOS from "aos";
import "aos/dist/aos.css";
const BusinessGlobally = () => {
    useEffect(() => {
        AOS.init();
        AOS.refresh();
      }, []);
    return (
        <section className='business_globally'>
            <Container>
                <Row className='align-items-center'>
                    <Col md="8" lg="9" className='mb-4 mb-lg-0'>
                        <div className='globally_heading' data-aos="fade-up"
     data-aos-duration="3000">
                            <h2>Elevate your business globally with Adzguru</h2>
                        </div>
                    </Col>

                    <Col md="12" lg="3" className='mb-4 mb-lg-0'>
                        <div className='globally_button'>
                            <div className="get_started_button globally_button_inner"><NavLink to="#">Get A Quote</NavLink></div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
        ); 
    }; 
export default BusinessGlobally;